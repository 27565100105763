<template>
  <div>
    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  No caminho da casa da tia para a igreja, uma criança de 6 anos
                  explica à assessora da Cáritas que agora mora em Mariana, com
                  sua mãe, mas sempre que possível gosta de ir a Camargos.
                  Questionada sobre qual dos dois lugares gosta mais de morar,
                  responde de pronto: “Em Camargos, porque aqui é maior”. Para
                  um adulto a resposta não tem lógica: é claro que a cidade de
                  Mariana é maior! Em todos os termos, seja em população, área,
                  quantidade de bairros, acesso à serviços etc, Mariana é maior
                  do que o povoado composto basicamente por duas ruas
                  entrecortadas por um cruzeiro e uma igreja que é Camargos.
                  Como, maior? “Em Camargos tem o pasto, que eu vou alimentar os
                  cavalos com o meu padrasto, tem a casa da minha tia, tem a
                  igreja, tem o Bento [Rodrigues] e Santa Rita [Durão], tem a
                  minha prima que às vezes vem pra cá também…”.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_crianca_adolescente_01.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            As crianças se divertem correndo do Zé Nestor, em setembro de 2019,
            na festa do Menino Jesus. Foto: Wigde Arcangelo / A Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_crianca_adolescente_02.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Foto: Larissa Helena. Fonte: Jornal A Sirene.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  Aquilo que é grande ou pequeno, significativo ou não, parte
                  das vivências e experiências de cada um, sendo muitas vezes
                  desconsiderada a perspectiva das crianças e adolescentes. E é
                  a partir dessa sensibilidade à dita casualmente a uma
                  assessora da Cáritas durante visita em uma das comunidades
                  rurais de Mariana, que se inicia esse texto sobre
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_crianca_adolescente' }"
                    >os modos de vida das crianças e adolescentes
                    <sup>1</sup></router-link
                  >
                  atingidas pelo rompimento da Barragem do Fundão.
                </p>
                <p>
                  A escuta atenta é um princípio orientador da atuação da
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_caritas_regional' }"
                    >Cáritas Brasileira Regional Minas Gerais</router-link
                  >
                  nos projetos de
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_assessoria_tecnica' }"
                    >Assessoria Técnica às Atingidas e Atingidos pelo Rompimento
                    da Barragem do Fundação em Mariana-MG</router-link
                  >. No presente texto traremos foco às crianças e adolescentes,
                  dando centralidade às suas perspectivas sobre o rompimento da
                  barragem e as transformações que isso acarretou em suas vidas.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="6" class="">
          <div class="iframe__box">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/CV-tnEl_Wuo"
              title="Mariana 4 anos: Plantando Resistência"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p style="font-size: 0.6em; text-align: center;">
            Plantando a Resistência: Memória, Direitos e Comunicação”. Realizada
            pela Cáritas Regional Minas Gerais e pela CABF em outubro de 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center flex-column">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  No âmbito do
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_cadastro' }"
                    >Cadastro</router-link
                  >
                  foi verificada a necessidade de criar um roteiro específico
                  para as
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_tomadas_de_termo' }"
                    >Tomadas de Termo</router-link
                  >
                  de crianças e adolescentes, dadas as peculiaridades desse
                  grupo de atingidos, cujo sofrimento e a forma de relatá-lo
                  requer uma adequação na ferramenta utilizada. Assim, foram
                  criados instrumentos que permitiram uma melhor percepção da
                  extensão dos danos sofridos por esse grupo vulnerável. Seja
                  por acompanhar os menores de idade para a realização da etapa,
                  seja por lerem o documento produzido a partir da entrevista, a
                  tomada de termo possibilitou à família muitas vezes perceber o
                  processo de sofrimento das crianças e adolescentes.
                </p>
                <p>
                  Uma liderança<sup>2</sup> conta que seu filho teve os estudos
                  prejudicados em razão do desastre criminoso e elucida o quão a
                  etapa do cadastro foi fundamental para ela e outras mães terem
                  percepção dessa dimensão dos danos na vida dos jovens. Segue o
                  relato descrito:
                </p>
                <p>
                  “[...] estudava edificações. A parte que mais me dói foi saber
                  disso na Tomada de Termo: ele não concluiu edificações,
                  faltando o último ano para formar, ele desistiu. Até hoje ele
                  não voltou a estudar. Ele só faz o ENEM com a esperança de
                  passar nele de novo, terminar o Ensino Médio de uma outra
                  forma, porque ele não conseguia frequentar as aulas. E várias
                  pessoas na tomada de termo, a Cáritas mesmo sabe disso, as
                  mães descobriram que os filhos estavam sofrendo demais da
                  conta. Muitos estavam sofrendo bullying na escola, chamando
                  eles de atingidos, chamando eles de folgados, falavam que o
                  povo é vagabundo, não tinha serviço, não tinha o que fazer,
                  que era culpa dos atingidos que a barragem estourou e que todo
                  mundo estava sem emprego [...]. ”
                </p>
              </template>
            </Card>

            <Card borderColor="#E06919" class="mt-5">
              <template v-slot:conteudo>
                <p>
                  Portanto, a Tomada de Termo para crianças e adolescentes
                  tornou-se ainda mais relevante, pois além de levantar os
                  danos, oportuniza à família uma melhor compreensão e
                  elaboração dos sofrimentos suportados por seu núcleo familiar.
                  Descobrir o sofrimento dos filhos possibilita à essa família
                  fornecer apoio que essas crianças precisam para lidar com o
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_crianca_adolescente_traumas',
                    }"
                    >trauma do rompimento</router-link
                  >.
                </p>
                <p>
                  O desastre-crime é um evento tão crítico na vida dessas
                  crianças e adolescentes que, quando perguntados nas
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_tomadas_de_termo' }"
                    >Tomadas de Termo</router-link
                  >
                  sobre o dia em que se deu o rompimento, o relato vem completo
                  de minúcias: o horário que acordaram, o que fizeram e com quem
                  interagiram, a roupa que vestiam, o momento em que tiveram que
                  correr ou ser carregados, como se salvaram, etc. Cumpre
                  destacar que essas entrevistas foram realizadas mais de dois
                  anos após o 5 de novembro de 2015, pois o
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_cadastro' }"
                    >Cadastro</router-link
                  >
                  da Cáritas começou a ocorrer apenas em 2018.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_crianca_adolescente_03.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Foto: Larissa Pinto / Jornal A Sirene, 2019.
          </p>
          <img
            max-width="100%"
            src="../../assets/conteudos_crianca_adolescente_04.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Foto: Larissa Pinto / Jornal A Sirene, 2019.
          </p>
          <img
            max-width="100%"
            src="../../assets/conteudos_crianca_adolescente_05.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Criança com pés descalços em cima de uma árvore, em Bento Rodrigues.
            Foto: Larissa Pinto/ A Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex flex-md-row-reverse">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                Para identificar os danos sofridos, as perguntas da etapa de
                Tomada de Termo buscam comparar a vida antes e depois do
                rompimento da barragem. Os relatos costumam retratar uma
                infância e adolescência feliz e despreocupada. O
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_crianca_adolescente' }"
                  >modo de vida das crianças e adolescentes</router-link
                >
                no território era de muita liberdade, podiam sair à rua para
                brincar, andar de bicicleta, encontrar os amigos.
              </p>
              <p>
                Constantemente se referem à relação com a natureza, pois
                pescavam e tomavam banho no rio, apanhavam frutas nos pomares.
                Os trabalhos na roça também eram valorizados: muitos auxiliavam
                os pais e avós nos cuidados dos animais e plantios, aprendendo
                com eles a cultivar a terra e estabelecendo com ela uma profunda
                ligação. As lembranças e memórias da zona rural são das mais
                diversas:
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-space-around align-center">
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center border__image mb-md-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            [...] num dia típico na comunidade: levantava, cuidava dos cavalos,
            ajudava meu avô, tirava leite das cabras, cortava capim e levava
            para passar na máquina. Nos finais de semana, costumava ir a festas
            das comunidades, próximas quando havia, ou andava a cavalo com meus
            pais e meu irmão. Sexo feminino, 17 anos.
          </p>
        </v-col>
        <v-col
          cols="10"
          md="5"
          class="d-flex flex-column justify-end align-end border__image mt-5 mt-md-10"
          style="z-index: 10;"
        >
          <p
            style="z-index: 15; text-align: center; padding-right: 3em;"
            class="ma-0"
          >
            Sempre pegava frutas perto da casa da avó, manga, goiaba, limão,
            laranja e cana de açúcar. Sexo feminino, 17 anos.
          </p>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            Em Paracatu, tinha a festa de Santo Antônio, com o levantamento de
            mastro. Lembro principalmente das Festas Juninas, que gostava mais,
            havia uma festa na escola e outra da comunidade. Nas festas as
            barraquinhas vendiam canjica, cachorro quente, caldo de feijão com
            linguiça, entre outras coisas. Gostava da quadrilha, e uma vez fui a
            noiva da festa. Tinha músicas e danças nas festas juninas. Não houve
            festa junina na escola do Rosário [bairro urbano de Mariana], mas
            fizeram a festa nas outras escolas. Em Paracatu a gente podia fazer
            as festas na quadra da escola, as festas que ocorreram no Morro de
            Santana e no centro de Mariana foram menores, além disso, são um
            pouco diferentes. Voltei a Paracatu de Baixo para a festa de Santo
            Antônio, em 2016 e 2017, mas foi diferente, prefiro do jeito que era
            antes. Sexo feminino, 11 anos.
          </p>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-space-around align-center">
        <v-col
          cols="10"
          md="5"
          class="d-flex flex-column justify-end align-end border__image mt-md-10 mt-5"
          style="z-index: 10;"
        >
          <p
            style="z-index: 15; text-align: center; padding-right: 5em;"
            class="ma-0"
          >
            [...] gostava de brincar na comunidade e gostava de brincar correndo
            descalça no quintal e de subir nas árvores para pegar frutas. [...]
            Ia pra escola a pé, junto com minha irmã e minha mãe, [..] às vezes
            apostava corrida com minha irmã. Sexo feminino, 9 anos.
          </p>
        </v-col>
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center border__image mb-md-10 mt-5 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            Eu ia pra cachoeira nadar, brincar. Em Camargos tinha muitos
            animais, passarinhos. Desde o rompimento não voltei pra Camargos.
            Sexo masculino, 7 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                Dentre as respondentes ao cadastro Cáritas de atingidas e
                atingidos de Mariana, havia 1412 crianças e adolescentes, isto
                é, pessoas com idade inferior a 18 anos na data do rompimento da
                Barragem de Fundão. Dessas, mais da metade (770 ou 54,5%) tinham
                até 11 anos, idade que caracteriza crianças conforme o que está
                preconizado no Estatuto da Criança e do Adolescente (ECA).
              </p>
              <p>
                Como o cadastro da Cáritas foi um processo contínuo e
                sistemático de acompanhamento aos atingidos e atingidas, temos
                informações também sobre algumas alterações na constituição das
                famílias. Dentre as pessoas menores de 18 anos cadastradas, 209
                crianças (14,8%) nasceram após o rompimento da barragem, ou
                seja, já expropriadas de todo um modo de vida que não existe
                mais, tal como vivenciado naqueles territórios.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex flex-md-row-reverse">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                Crianças e adolescentes, tendo como referência a data do
                rompimento da barragem, representam 29% da população atingida
                (4.958 pessoas, na base de dados ainda em curso no mês de março
                de 2022). Isto é, um contingente considerável de pessoas que na
                Constituição Federal Brasileira são tratadas com absoluta
                prioridade no que diz respeito à efetivação de direitos por
                parte do Estado e de toda a sociedade.
              </p>
              <p>
                As análises que seguem foram elaborados com base em quarenta e
                sete das quase oitocentas Tomadas de Termo realizadas com
                crianças e adolescentes no processo de cadastramento. Os textos
                são uma tentativa de organizar trechos dessas tomadas de termo,
                dando destaque para os relatos trazidos e, assim, visibilizar as
                narrativas dessas crianças e adolescentes. Ademais, cumpre
                reforçar que as análises aqui esboçadas sustentam-se nas
                percepções e reflexões elaboradas pela própria equipe da Cáritas
                Brasileira Regional Minas Gerais durante os mais de quatro anos
                de trabalho em Mariana com os atingidos e atingidas pelo
                rompimento da Barragem de Fundão, em que foi possível observar
                padrões e recorrências.
              </p>
              <p style="text-align: center">
                <strong
                  >Autoria: Jéssica Pinheiro, Laís Jabace, Maria Carolina
                  Zanetti, Marisa Versiani, Paula Zanardi, Pedro Paulo Gonçalves
                  e Thalita Araújo.</strong
                >
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb" style="background-color: white;">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12">
          <img
            src="../../assets/conteudos_crianca_adolescente_grafico.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> As pessoas retratadas neste texto são caracterizadas
              à luz do Estatuto da Criança e Adolescente (ECA), Lei nº 8.069/90.
              Neste estatuto, são crianças aquelas com até onze anos completos,
              e adolescentes, aqueles de doze até os dezessete anos completos.
              Este ordenamento jurídico surge, nos anos 90, promovendo o
              princípio da Proteção Integral em equilíbrio ao princípio do
              Melhor Interesse da Criança e do Adolescente (SOUZA; PEREIRA,
              2020, p. 46), possibilitando que estes indivíduos sejam vistos
              como pessoas de direito (LISBOA; SOUZA, 2019, p. 9). A proteção
              integral conferida às crianças e aos adolescentes pelo ordenamento
              jurídico foi inaugurada pela Constituição Federal de 1988, que os
              reconheceu como sujeitos de direitos, estabelecendo o dever de
              respeitar sua condição de desenvolvimento peculiar. A partir da
              Constituição Federal, em seu artigo 227, restou determinado à
              família, à sociedade e ao Estado o dever de assegurar à criança e
              ao adolescente, com absoluta prioridade, seus direitos à vida, à
              saúde, à alimentação, à educação, ao lazer, à profissionalização,
              à cultura, à dignidade, ao respeito, à liberdade e à convivência
              familiar e comunitária.
            </p>
            <p>
              <sup>2</sup> Atingida de Paracatu de Baixo e membro da Comissão de
              Atingidos e Atingidas da Barragem de Fundão e da Comissão de
              Atingidos e Atingidas de Paracatu de Baixo. Menção ao relato no
              vídeo “LUZIA QUEIROZ, 5 anos sem justiça”. Disponível em:
              https://www.youtube.com/watch?v=et2PoqLmSq4&ab_channel=C%C3%A1ritasMinasGerais
            </p>
            <p>
              <sup>3</sup> Dados considerando as famílias cadastradas pela
              Cáritas até dezembro de 2021.
            </p>
            <p>
              <sup>4</sup> Para este texto foram analisadas quarenta e sete
              tomadas de termo de crianças e adolescentes, entre sete e
              dezessete anos, que residiam nas comunidades de Bento Rodrigues,
              Pedras, Ponte do Gama, Santa Rita Durão, Camargos, Campinas,
              Paracatu de Cima e Paracatu de Baixo, no momento do rompimento da
              barragem do Fundão. A seleção das tomadas de termo teve como
              parâmetro orientador os danos imateriais identificados a partir do
              relatado por tais crianças e adolescentes para as categorias:
              perdas e danos sobre os saberes, modos de fazer e modos de vida
              locais; danos às relações com a comunidade, amigos e vizinhança;
              danos à integridade psíquica; danos à integridade física; danos ao
              desenvolvimento da criança ou do adolescente. Entre os 23 danos
              imateriais utilizados para a sistematização das tomadas de termo
              foram escolhidos apenas os cinco danos descritos acima. A seleção
              surge da percepção das assessoras técnicas da Cáritas que há cinco
              anos acompanham os relatos das famílias e identificam na rotina do
              trabalho os principais danos sofridos pelas crianças e
              adolescentes. Dessa forma, este texto não apresenta um
              levantamento exaustivo dos dados qualitativos produzidos pelo
              cadastro, muito mais se aproxima de uma análise das falas destas
              crianças a partir de discussões promovidas no Grupo de Trabalho
              Inter-projetos Criança e Adolescente, e da experiência
              institucional e profissional da Cáritas no território.
            </p>
            <p>
              <sup>5</sup> Especialista em advocacia extrajudicial;
              Pós-graduanda em Direito Público pela Escola Brasileira de
              Direito. Assessora Jurídica na ATI Mariana.
            </p>
            <p>
              <sup>6</sup> Cientista Social (UFMG), mestra e doutoranda em
              Planejamento Urbano e Regional (IPPUR/UFRJ). Desde 2020 é
              Coordenadora Operacional da Assessoria Técnica aos Atingidos e
              Atingidas pelo Rompimento da Barragem do Fundão em Mariana pela
              Cáritas Brasileira Regional Minas Gerais.
            </p>
            <p>
              <sup>7</sup> Graduanda em Direito na Universidade Federal de Ouro
              Preto. Assessora Técnica Nível Médio na ATI Mariana.
            </p>
            <p>
              <sup>8</sup> Bacharela em Direito pela Faculdade de Direito de
              Conselheiro Lafaiete. Assessora Jurídica na ATI Mariana.
            </p>
            <p>
              <sup>9</sup> Cientista Social pela UFSC, mestra em Preservação do
              Patrimônio Cultural pelo IPHAN. Assessora técnica na ATI Mariana.
            </p>
            <p>
              <sup>10</sup> Mestre em Planejamento Urbano e Regional
              (IPPUR/UFRJ), Graduado em Sociologia (Unitau) e Relações
              Internacionais (PUC Minas). Assessor Técnico na ATI Mariana.
            </p>
            <p>
              <sup>11</sup> Mestranda em Direito pela Universidade Federal de
              Ouro Preto. Bacharela em Direito pela mesma Universidade.
              Assessora Jurídica na ATI Mariana.
            </p>
            <p>
              <sup>12</sup> Doutor em Educação (UFMG), Mestre em Sociologia
              (UFMG), Graduado em Ciências Sociais (UFMG). Assessor Técnico na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image2 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image2 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_crianca_adolescente_text_bg_01.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_crianca_adolescente_text_bg_02.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em;
}

.iframe__box {
  position: relative;
  padding-bottom: 56.1%;
  height: 0;
  overflow: hidden;
}

.iframe__box iframe,
.iframe__box object,
.iframe__box embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
